<template>
    <v-card outlined class="rounded">
        <v-card-title class="pl-6 pl-md-7 card-title">{{ $t('components.filters.common_title') }}</v-card-title>
        <v-card-text>
            <CustomersFilterForm
                ref="form"
                class="pa-2"
                :value="filter"
                :isFormValid.sync="isFormValid"
                :loading="loading"
                @submit="$emit('filtrate')"
            >
                <template #submit>
                    <div class="d-flex justify-end">
                        <v-btn type="submit" :width="$vuetify.breakpoint.smAndDown ? '100%' : '86px'" depressed class="float-right" color="primary" :loading="loading" :disabled="!isFormValid">
                            {{ $t('actions.filtrate') }}
                        </v-btn>
                    </div>
                </template>
            </CustomersFilterForm>
        </v-card-text>
    </v-card>
</template>

<script>
import CustomersFilterForm from '@/components/forms/filters/CustomersFilterForm'
import CustomersFilter     from '@/models/CustomersFilter'

export default {
    name: 'CustomersFilter',
    props: {
        filter: {
            type: CustomersFilter,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    components: { CustomersFilterForm },
    data: vm => {
        return {
            isFormValid: false,
        }
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-title {
    font-size: 16px;
}
</style>