var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "rounded", attrs: { outlined: "" } },
    [
      _c("v-card-title", { staticClass: "pl-6 pl-md-7 card-title" }, [
        _vm._v(_vm._s(_vm.$t("components.filters.common_title")))
      ]),
      _c(
        "v-card-text",
        [
          _c("CustomersFilterForm", {
            ref: "form",
            staticClass: "pa-2",
            attrs: {
              value: _vm.filter,
              isFormValid: _vm.isFormValid,
              loading: _vm.loading
            },
            on: {
              "update:isFormValid": function($event) {
                _vm.isFormValid = $event
              },
              "update:is-form-valid": function($event) {
                _vm.isFormValid = $event
              },
              submit: function($event) {
                return _vm.$emit("filtrate")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "submit",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-end" },
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "float-right",
                            attrs: {
                              type: "submit",
                              width: _vm.$vuetify.breakpoint.smAndDown
                                ? "100%"
                                : "86px",
                              depressed: "",
                              color: "primary",
                              loading: _vm.loading,
                              disabled: !_vm.isFormValid
                            }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("actions.filtrate")) + " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }