<template>
    <v-form @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="6" class="px-md-1">
                <cp-text-field
                    ref="name_field"
                    :label="$t('forms.filters.CustomersFilterForm.name.label')"
                    :hint="$t('forms.filters.CustomersFilterForm.name.hint')"
                    :placeholder="$t('forms.filters.CustomersFilterForm.name.placeholder')"
                    type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                    :disabled="loading"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" class="px-md-1">
                <cp-text-field
                    ref="document_field"
                    :label="$t('forms.filters.CustomersFilterForm.document.label')"
                    :hint="$t('forms.filters.CustomersFilterForm.document.hint')"
                    :placeholder="$t('forms.filters.CustomersFilterForm.document.placeholder')"
                    type="text"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                    :disabled="loading"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" class="px-md-1">
                <cp-text-field
                    ref="email_field"
                    :label="$t('forms.filters.CustomersFilterForm.email.label')"
                    :hint="$t('forms.filters.CustomersFilterForm.email.hint')"
                    :placeholder="$t('forms.filters.CustomersFilterForm.email.placeholder')"
                    type="text"
                    :error-messages="getErrorMessages('email')"
                    v-model="value.email"
                    @input="clearErrorMessages('email')"
                    :disabled="loading"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>

            <v-col cols="12" xs="12" sm="12" md="6" class="px-md-1">
                <cp-text-field
                    ref="phone_field"
                    :label="$t('forms.filters.CustomersFilterForm.phone.label')"
                    :hint="$t('forms.filters.CustomersFilterForm.phone.hint')"
                    :placeholder="$t('forms.filters.CustomersFilterForm.phone.placeholder')"
                    type="text"
                    :error-messages="getErrorMessages('phone')"
                    v-model="value.phone"
                    @input="clearErrorMessages('phone')"
                    :disabled="loading"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import CustomersFilter from '@/models/CustomersFilter'
import FormMixin       from '@/mixins/FormMixin'

export default {
    name: 'CustomersFilterForm',
    mixins: [ FormMixin(CustomersFilter, 'name_field') ],
}
</script>