import BaseModel from '@/models/BaseModel'
import _ from 'lodash'

export default class Filter extends BaseModel {

    getLikeAttributesFilterParams() {
        let params = {}
        let data   = this.getData()
        for (let prop in data) {
            const value = data[prop]
            if (!_.isNil(value)) {
                params[`filter.like.${prop}`] = String(value)
            }
        }
        return params
    }

}