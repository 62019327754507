<template>
    <BaseCrudTable 
        ref="table" 
        tableName="CustomersTable" 
        :headerConfigs="headerConfigs"
        :fetchFunction="fetchFunction"
        :pagination="pagination"
        @update:loading="value => loading = value"
        @create="handleCreate" 
        @details="handleDetails"
        @edit="handleEdit" 
        @delete="handleDelete"
    >
        <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:icons.start="{ item }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn 
                        v-on="on" icon
                        @click="showHistory(item)"
                        class="mr-4"
                    >
                        <v-icon medium :color="isLightTheme ? 'indigo accent-4' : 'primary'">$cp_invoice</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('tables.CustomersTable.tooltips.history') }}</span>
            </v-tooltip>
        </template>

        <template #actions>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-can:create="'Report'" icon small class="mr-sm-1" :to="{ name: 'reports' }" v-bind="attrs" v-on="on">
                        <v-icon small class="secondary_font--text">$cp_file</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('globals.reports') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-can:create="'ImportEntry'" icon small class="ml-sm-1 mr-sm-1" :to="{ name: 'import.customer' }" v-bind="attrs" v-on="on">
                        <v-icon small class="secondary_font--text">$cp_upload</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('actions.import') }}</span>
            </v-tooltip>

            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small class="ml-sm-1 mr-0 mr-sm-4" v-bind="attrs" v-on="on" @click="changeFilterVisibility">
                        <v-icon small :class="showFilter ? 'primary--text' : 'secondary_font--text'">$cp_filter</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('actions.filtrate') }}</span>
            </v-tooltip>
        </template>

        <template #search>
            <v-row v-if="!showFilter" class="justify-end">
                <v-col cols="12" md="4">
                    <v-text-field
                        ref="exact_document_field"
                        :label="$t('tables.CustomersTable.search.exact_document.label')"
                        :hint="$t('tables.CustomersTable.search.exact_document.hint')"
                        :placeholder="$t('tables.CustomersTable.search.exact_document.placeholder')"
                        v-model="searchDocument"
                        outlined dense
                        class="mr-4"
                        append-outer-icon="mdi-send"
                        @click:append-outer="listByDocument"
                        @keyup.enter="listByDocument"
                        type="number"
                    />
                </v-col>
            </v-row>
        </template>

        <template #filter>
            <v-expand-transition>
                <CustomersFilterComponent
                    ref="filter"
                    v-if="showFilter"
                    class="mb-sm-2 mx-0 mx-sm-4"
                    :filter="filter"
                    :loading="loading"
                    @filtrate="$emit('filtrate', filter)"
                />
            </v-expand-transition>
        </template>
    </BaseCrudTable>
</template>

<script>
import CustomersFilterComponent from '@/components/filters/CustomersFilter'
import BaseCrudTable            from '@/components/tables/BaseCrudTable'
import RateColor                from '@/shared/mixins/RateColor'
import CustomersFilter          from '@/models/CustomersFilter'

export default {
    name: 'CustomersTable',
    props: {
        fetchFunction: {
            type: Function,
            required: true,
        },
    },
    mixins: [ RateColor ],
    components: { BaseCrudTable, CustomersFilterComponent },
    data: () => ({
        searchDocument: null,
        filter    : new CustomersFilter,
        showFilter: false,
        pagination: {
            page    : 1,
            per_page: 5,
        }, 
        headerConfigs: [
            {
                value: 'name',
                showOnMobile: true,
            },
            {
                value: 'email',
                showOnMobile: false,
            },
            {
                value: 'phone',
                showOnMobile: false,
            },
        ],
        loading: false,
    }),
    created() {
        this.pagination.page = Number(this.$route.query.page) || this.pagination.page || 1
    },
    methods: {
        async refresh(additionalParams = {}) {
            await this.$refs.table.preFetchItems(additionalParams)
        },
        changeFilterVisibility() {
            this.showFilter = !this.showFilter
            if (!this.showFilter)
                this.$emit('filtrate')
        },
        handleCreate() {
            this.$emit('create')
        },
        handleDetails(item) {
            this.$emit('details', item)
        },
        handleEdit(item) {
            this.$emit('edit', item)
        },
        handleDelete(item) {
            this.$emit('delete', item)
        },
        showHistory(item) {
            this.$router.push({ name: 'history', params: { id: item.id, document: item.document } })
        },
        listByDocument() {
            this.$emit('list-by-document', this.searchDocument)
        },
    },
}
</script>