var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCrudTable", {
    ref: "table",
    attrs: {
      tableName: "CustomersTable",
      headerConfigs: _vm.headerConfigs,
      fetchFunction: _vm.fetchFunction,
      pagination: _vm.pagination
    },
    on: {
      "update:loading": function(value) {
        return (_vm.loading = value)
      },
      create: _vm.handleCreate,
      details: _vm.handleDetails,
      edit: _vm.handleEdit,
      delete: _vm.handleDelete
    },
    scopedSlots: _vm._u(
      [
        _vm.$vuetify.breakpoint.mdAndUp
          ? {
              key: "icons.start",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-4",
                                      attrs: { icon: "" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showHistory(item)
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          medium: "",
                                          color: _vm.isLightTheme
                                            ? "indigo accent-4"
                                            : "primary"
                                        }
                                      },
                                      [_vm._v("$cp_invoice")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("tables.CustomersTable.tooltips.history")
                          )
                        )
                      ])
                    ]
                  )
                ]
              }
            }
          : null,
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "can",
                                      rawName: "v-can:create",
                                      value: "Report",
                                      expression: "'Report'",
                                      arg: "create"
                                    }
                                  ],
                                  staticClass: "mr-sm-1",
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    to: { name: "reports" }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "secondary_font--text",
                                  attrs: { small: "" }
                                },
                                [_vm._v("$cp_file")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("globals.reports")))])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  directives: [
                                    {
                                      name: "can",
                                      rawName: "v-can:create",
                                      value: "ImportEntry",
                                      expression: "'ImportEntry'",
                                      arg: "create"
                                    }
                                  ],
                                  staticClass: "ml-sm-1 mr-sm-1",
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    to: { name: "import.customer" }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "secondary_font--text",
                                  attrs: { small: "" }
                                },
                                [_vm._v("$cp_upload")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("actions.import")))])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-sm-1 mr-0 mr-sm-4",
                                  attrs: { icon: "", small: "" },
                                  on: { click: _vm.changeFilterVisibility }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  class: _vm.showFilter
                                    ? "primary--text"
                                    : "secondary_font--text",
                                  attrs: { small: "" }
                                },
                                [_vm._v("$cp_filter")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("actions.filtrate")))])]
              )
            ]
          },
          proxy: true
        },
        {
          key: "search",
          fn: function() {
            return [
              !_vm.showFilter
                ? _c(
                    "v-row",
                    { staticClass: "justify-end" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            ref: "exact_document_field",
                            staticClass: "mr-4",
                            attrs: {
                              label: _vm.$t(
                                "tables.CustomersTable.search.exact_document.label"
                              ),
                              hint: _vm.$t(
                                "tables.CustomersTable.search.exact_document.hint"
                              ),
                              placeholder: _vm.$t(
                                "tables.CustomersTable.search.exact_document.placeholder"
                              ),
                              outlined: "",
                              dense: "",
                              "append-outer-icon": "mdi-send",
                              type: "number"
                            },
                            on: {
                              "click:append-outer": _vm.listByDocument,
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.listByDocument($event)
                              }
                            },
                            model: {
                              value: _vm.searchDocument,
                              callback: function($$v) {
                                _vm.searchDocument = $$v
                              },
                              expression: "searchDocument"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        },
        {
          key: "filter",
          fn: function() {
            return [
              _c(
                "v-expand-transition",
                [
                  _vm.showFilter
                    ? _c("CustomersFilterComponent", {
                        ref: "filter",
                        staticClass: "mb-sm-2 mx-0 mx-sm-4",
                        attrs: { filter: _vm.filter, loading: _vm.loading },
                        on: {
                          filtrate: function($event) {
                            return _vm.$emit("filtrate", _vm.filter)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }