<template>
    <BaseModal ref="modal"
        @cancel="cancel" @confirm="triggerSubmit"
        v-model="isModalOpen"
        :title="title"
        :confirmButton="computedConfirmButton"
        :cancelButton="computedCancelButton"
        max-width="330px"
        persistent
    >
        <CustomerForm
            ref="customer_form"
            v-if="!loading"
            v-model="customer"
            :isFormValid.sync="isFormValid"
            :useCNPJ="useCNPJ"
            :formType="'CREATE'"
            @submit="confirm"
            @triggerSubmit="triggerSubmit"
            @document-changed="changeDocument"
            :loading="loading"
        />
        <span v-else>{{ $t('forms.wait') }}</span>
    </BaseModal>
</template>

<script>
import CustomerForm from '@/components/forms/CustomerForm'
import BaseModal from '@/components/modals/BaseModal'
import BaseModalMixin from '../BaseModalMixin'
import Customer from '@/models/Customer'

export default {
    name: 'CustomersCreateModal',
    mixins: [ BaseModalMixin ],
    components: { BaseModal, CustomerForm },
    props: {
        customer: {
            type: Customer,
            required: true,
        },
    },
    data: vm => {
        return {
            useCNPJ: false,
            isFormValid: false,
            title: vm.$t('views.customers.Create.title'),
            loading: false,
        }
    },
    created() {
        this.customer.use_cnpj = !!this.customer.document && !!(this.customer.document.replace(/\D/g,'').length == 14)
    },
    methods: {
        triggerSubmit() {
            this.$refs.customer_form.submit()
        },
        async beforeConfirm(customer) {
            if (!this.isFormValid)
                return false

            this.loading = true
            customer.firebaseAction = 'CREATE'
            customer.login_type = this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')
            customer.require_password = true
            customer.formatToBack()

            let errType
            let response = await customer.create()
                .catch(err => {
                    if (err.response && err.response.data == 'Error sending confirmation email')
                        return

                    if (err.message == 'Network Error') {
                        errType = 'network_error'
                        return 
                    }

                    if (err.response && err.response.data.type) {
                        errType = err.response.data.type
                        return
                    }

                    errType = 'error'
                })

            this.customer.formatToFront()
            
            if (errType) {
                this.loading = false
                const definedErrors = [
                    'used_email',
                    'used_phone',
                    'used_document',
                    'network_error'
                ]
                errType = definedErrors.includes(errType) ? errType : 'error'
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`views.customers.Create.alerts.${errType}`), 'error', resolve)
                })
                return false
            }

            this.loading = false

            this.resolveValue = this.customer
            return true
        },
        changeDocument(value) {
            this.useCNPJ = value
            this.customer.use_cnpj = this.useCNPJ
        },
    },
    computed: {
        computedConfirmButton() {
            return {
                label: this.$t('actions.save'),
                disabled: !this.isFormValid,
                loading: this.loading,
            }
        },
        computedCancelButton() {
            return {
                disabled: this.loading,
            }
        },
    },
}
</script>