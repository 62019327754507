<template>
    <v-form ref="form" @submit.prevent="submit" lazy-validation v-model="isFormValidLocal">
        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field   
                    ref="name_field"
                    autofocus
                    :label="$t('forms.CustomerForm.name.label')"
                    :hint="$t('forms.CustomerForm.name.hint')"
                    :placeholder="$t('forms.CustomerForm.name.placeholder')"
                    required type="text"
                    :error-messages="getErrorMessages('name')"
                    v-model="value.name"
                    @input="clearErrorMessages('name')"
                />
            </v-col>

            <v-col cols="12" class="py-0">
                <cp-document-field
                    ref="document_field"
                    :label="$t(`forms.CustomerForm.document.label.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    :hint="formType !== 'CREATE' ? $t('forms.CustomerForm.document.disabled_hint') : $t('forms.CustomerForm.document.hint')"
                    persistent-hint
                    :placeholder="$t(`forms.CustomerForm.document.placeholder.${computedUseCNPJ ? 'cnpj' : 'cpf'}`)"
                    required type="text"
                    :useCNPJ.sync="computedUseCNPJ"
                    :error-messages="getErrorMessages('document')"
                    v-model="value.document"
                    @input="clearErrorMessages('document')"
                    :disabled="formType !== 'CREATE'"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="py-0">
                <cp-text-field 
                    ref="email_field"
                    :label="$t('forms.CustomerForm.email.label')"
                    :hint="value.login_type && !isEmailLogin ? $t('forms.CustomerForm.email.disabled_hint') : $t('forms.CustomerForm.email.hint')"
                    persistent-hint
                    :placeholder="$t('forms.CustomerForm.email.placeholder')"
                    required type="text"
                    :error-messages="getEmailErrorMessages()"
                    v-model="value.email"
                    @input="clearErrorMessages('email')"
                    :disabled="value.login_type && !isEmailLogin"
                />
            </v-col>

            <v-col cols="12" class="py-0">
                <cp-phone-field
                    ref="phone_field"
                    :label="$t('forms.CustomerForm.phone.label')"
                    :hint="$t('forms.CustomerForm.phone.hint')"
                    :placeholder="$t('forms.CustomerForm.phone.placeholder')"
                    required type="text"
                    :error-messages="this.getErrorMessages('phone')"
                    v-model="value.phone"
                    @input="clearErrorMessages('phone')"
                    @keyup.enter="$emit('triggerSubmit')"
                />
            </v-col>
        </v-row>

        <!-- Slot para campos adicionais -->
        <slot></slot>

        <!-- Slot para botão de submit -->
        <slot name="submit"></slot>

    </v-form>
</template>

<script>
import customer       from '@/models/Customer'
import FormMixin      from '@/mixins/FormMixin'

export default {
    name: 'CustomerForm',
    mixins: [ FormMixin(customer, 'name_field') ],
    props: {
        customer: Object,
        formType: String,
        useCNPJ: Boolean,
    },
    methods: {
        // Caso o usuário tenha login por email, impede que o mesmo deixa de informar um email
        getEmailErrorMessages() {
            let emailField = this.$refs.email_field

            if (this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL') && emailField && !emailField.localValue) 
                return [this.$t('forms.CustomerForm.email.required')].concat(this.getErrorMessages('email'))

            return this.getErrorMessages('email')
        },
        // Caso o usuário tenha login por phone, impede que o mesmo deixa de informar um número
        // [FID-653] Comentado temporariamente enquanto não habilitamos o telefone
        // getPhoneErrorMessages() {
        //     let phoneField = this.$refs.phone_field

        //     if (this.value.login_type == 'PHONE' && phoneField && (!phoneField.localValue || !phoneField.localValue.replace(/\D/g,'')))
        //         return [this.$t('forms.CustomerForm.phone.required')].concat(this.getErrorMessages('phone'))

        //     return this.getErrorMessages('phone')
        // },
        // Confere se a senha do input é igual a sua confimação e, caso não seja, atribuí este erro ao array de erros
        getConfirmationPasswordErrorMessages() {
            let passwordField              = this.formType == 'CREATE' ? this.$refs.password_field : this.$refs.password_update_field
            let passwordConfirmationField  = this.formType == 'CREATE' ? this.$refs.password_confirmation_field : this.$refs.password_confirmation_update_field
            let passwordConfirmationErrors = this.formType == 'CREATE' ? this.getErrorMessages('password_confirmation') : this.getErrorMessages('password_confirmation_update')

            let mismatchError = []
            if ( passwordField && passwordConfirmationField &&
                 passwordField.localValue != passwordConfirmationField.localValue ) {

                return [this.$t('forms.CustomerForm.password_confirmation.mismatch')].concat(passwordConfirmationErrors)
            }
                        
            return passwordConfirmationErrors
        },
        getLocalPassword() {
            return this.formType == 'CREATE' ? this.$refs.password_field.computedValue : this.$refs.password_update_field.computedValue 
        },
    },
    computed: {
        computedUseCNPJ: {
            get() {
                return this.useCNPJ
            },
            set(value) {
                this.$emit('document-changed', value)
            }
        },
        isEmailLogin() {
            return this.value.login_type == this.$constants.getConstant('CUSTOMER_LOGIN_TYPE_EMAIL')
        },
    },
}
</script>