var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  autofocus: "",
                  label: _vm.$t("forms.CustomerForm.name.label"),
                  hint: _vm.$t("forms.CustomerForm.name.hint"),
                  placeholder: _vm.$t("forms.CustomerForm.name.placeholder"),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getErrorMessages("name")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("name")
                  }
                },
                model: {
                  value: _vm.value.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-document-field", {
                ref: "document_field",
                attrs: {
                  label: _vm.$t(
                    "forms.CustomerForm.document.label." +
                      (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                  ),
                  hint:
                    _vm.formType !== "CREATE"
                      ? _vm.$t("forms.CustomerForm.document.disabled_hint")
                      : _vm.$t("forms.CustomerForm.document.hint"),
                  "persistent-hint": "",
                  placeholder: _vm.$t(
                    "forms.CustomerForm.document.placeholder." +
                      (_vm.computedUseCNPJ ? "cnpj" : "cpf")
                  ),
                  required: "",
                  type: "text",
                  useCNPJ: _vm.computedUseCNPJ,
                  "error-messages": _vm.getErrorMessages("document"),
                  disabled: _vm.formType !== "CREATE"
                },
                on: {
                  "update:useCNPJ": function($event) {
                    _vm.computedUseCNPJ = $event
                  },
                  "update:use-c-n-p-j": function($event) {
                    _vm.computedUseCNPJ = $event
                  },
                  input: function($event) {
                    return _vm.clearErrorMessages("document")
                  }
                },
                model: {
                  value: _vm.value.document,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "document", $$v)
                  },
                  expression: "value.document"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-text-field", {
                ref: "email_field",
                attrs: {
                  label: _vm.$t("forms.CustomerForm.email.label"),
                  hint:
                    _vm.value.login_type && !_vm.isEmailLogin
                      ? _vm.$t("forms.CustomerForm.email.disabled_hint")
                      : _vm.$t("forms.CustomerForm.email.hint"),
                  "persistent-hint": "",
                  placeholder: _vm.$t("forms.CustomerForm.email.placeholder"),
                  required: "",
                  type: "text",
                  "error-messages": _vm.getEmailErrorMessages(),
                  disabled: _vm.value.login_type && !_vm.isEmailLogin
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("email")
                  }
                },
                model: {
                  value: _vm.value.email,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "email", $$v)
                  },
                  expression: "value.email"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12" } },
            [
              _c("cp-phone-field", {
                ref: "phone_field",
                attrs: {
                  label: _vm.$t("forms.CustomerForm.phone.label"),
                  hint: _vm.$t("forms.CustomerForm.phone.hint"),
                  placeholder: _vm.$t("forms.CustomerForm.phone.placeholder"),
                  required: "",
                  type: "text",
                  "error-messages": this.getErrorMessages("phone")
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("phone")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "phone", $$v)
                  },
                  expression: "value.phone"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }