import Filter from '@/models/Filter'
import { string, length, isnil, numeric, gte } from '@/utils/validation'

export default class CustomersFilter extends Filter {

    defaults() {
        return {
            name    : undefined,
            document: undefined,
            email   : undefined,
            phone   : undefined,
        }
    }

    validation() {
        return {
            name    : (string.and(length(1, 191))).or(isnil),
            document: (numeric.and(gte(0)).and(length(1, 191))).or(isnil),
            email   : (string.and(length(1, 191))).or(isnil),
            phone   : (numeric.and(gte(0)).and(length(1, 191))).or(isnil),
        }
    }

}