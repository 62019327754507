var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit($event)
        }
      },
      model: {
        value: _vm.isFormValidLocal,
        callback: function($$v) {
          _vm.isFormValidLocal = $$v
        },
        expression: "isFormValidLocal"
      }
    },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "px-md-1",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "name_field",
                attrs: {
                  label: _vm.$t("forms.filters.CustomersFilterForm.name.label"),
                  hint: _vm.$t("forms.filters.CustomersFilterForm.name.hint"),
                  placeholder: _vm.$t(
                    "forms.filters.CustomersFilterForm.name.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("name"),
                  disabled: _vm.loading
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("name")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.name,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "name", $$v)
                  },
                  expression: "value.name"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-md-1",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "document_field",
                attrs: {
                  label: _vm.$t(
                    "forms.filters.CustomersFilterForm.document.label"
                  ),
                  hint: _vm.$t(
                    "forms.filters.CustomersFilterForm.document.hint"
                  ),
                  placeholder: _vm.$t(
                    "forms.filters.CustomersFilterForm.document.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("document"),
                  disabled: _vm.loading
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("document")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.document,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "document", $$v)
                  },
                  expression: "value.document"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-md-1",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "email_field",
                attrs: {
                  label: _vm.$t(
                    "forms.filters.CustomersFilterForm.email.label"
                  ),
                  hint: _vm.$t("forms.filters.CustomersFilterForm.email.hint"),
                  placeholder: _vm.$t(
                    "forms.filters.CustomersFilterForm.email.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("email"),
                  disabled: _vm.loading
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("email")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.email,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "email", $$v)
                  },
                  expression: "value.email"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "px-md-1",
              attrs: { cols: "12", xs: "12", sm: "12", md: "6" }
            },
            [
              _c("cp-text-field", {
                ref: "phone_field",
                attrs: {
                  label: _vm.$t(
                    "forms.filters.CustomersFilterForm.phone.label"
                  ),
                  hint: _vm.$t("forms.filters.CustomersFilterForm.phone.hint"),
                  placeholder: _vm.$t(
                    "forms.filters.CustomersFilterForm.phone.placeholder"
                  ),
                  type: "text",
                  "error-messages": _vm.getErrorMessages("phone"),
                  disabled: _vm.loading
                },
                on: {
                  input: function($event) {
                    return _vm.clearErrorMessages("phone")
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("triggerSubmit")
                  }
                },
                model: {
                  value: _vm.value.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.value, "phone", $$v)
                  },
                  expression: "value.phone"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default"),
      _vm._t("submit")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }