<template>
    <v-container fluid class="pt-0">
        <CreateModal ref="create_modal" :customer="selectedCustomer" />
        <EditModal ref="edit_modal" :customer="selectedCustomer" />

        <span class="pl-5 pl-md-12 section-title">{{ $t('views.customers.title') }}</span>
        <v-alert v-if="hasError" type="error" class="mt-4 mx-5 mt-md-6 mx-md-12">
            {{ $t('errors.generic') }}
        </v-alert>
        <CustomersTable
            v-else
            ref="table"
            class="my-4 mx-2 mx-md-10"
            :fetchFunction="fetchFunction"
            @filtrate="filtrate"
            @create="openCreate" 
            @details="openDetails" 
            @edit="openEdit"
            @delete="handleDelete"
            @list-by-document="listByDocument"
        />
    </v-container>
</template>

<script>
import CreateModal          from '@/components/modals/customers/CreateModal'
import EditModal            from '@/components/modals/customers/EditModal'
import CustomersTable       from '@/components/tables/CustomersTable'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import Customer             from '@/models/Customer'

export default {
    data: () => ({
        selectedCustomer: new Customer,
        filterParams    : {},
        searchDocument  : null,
        hasError        : false,
    }),
    mixins: [ HasErrorHandlerMixin ],
    components: { CustomersTable, CreateModal, EditModal },
    methods: {
        async openCreate() {
            this.selectedCustomer = new Customer()
            let r = await this.$refs.create_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.customers.List.alerts.create_success'), 'success')
            await this.refreshTable()
        },
        async openDetails(customer) {
            this.selectedCustomer = customer
            if (!customer)
                return

            this.$router.push({ name: 'customers.details', params: {
                id      : this.selectedCustomer.id,
                document: (this.selectedCustomer.document || '').replace(/\D/g, ''),
            } })
        },
        async openEdit(customer) {
            // Precisa criar uma nova instância
            this.selectedCustomer = new Customer(this.$lodash.cloneDeep(customer))
            let r = await this.$refs.edit_modal.open()
            if (!r)
                return

            this.$bus.$emit('message', this.$t('views.customers.List.alerts.edit_success'), 'success')
            await this.refreshTable()
        },
        async handleDelete(customer) {
            await customer.delete()
                .catch(this.preErrorHandler)

            if (this.hasError) {
                this.hasError = false
                return
            }

            this.$bus.$emit('message', this.$t('views.customers.List.alerts.delete_success'), 'success')
            await this.refreshTable()
        },
        filtrate(filter) {
            this.filterParams = filter ? filter.getLikeAttributesFilterParams() : {}
            this.searchDocument = null
            this.refreshTable()
        },
        listByDocument(searchDocument) {
            this.searchDocument = searchDocument
            this.refreshTable()
        },
        async refreshTable() {
            await this.$refs.table.refresh(this.filterParams)
        },
        async fetchFunction(pagination, additionalParams = {}) {
            this.hasError = false

            let response
            if (!this.searchDocument) {
                response = await Customer.list(pagination, additionalParams)
                    .catch(this.preErrorHandler)
            } else {
                response = await Customer.getByDocument(this.searchDocument)
                    .catch(err => {
                        const statusCode = this.$lodash.get(err, 'response.status')                        
                        if (statusCode === 404) {
                            return
                        }

                        this.preErrorHandler(err)
                    })
                                
                response = {
                    data: response ? [response.getData()] : [],
                }
            }
            
            return await Customer.hydrateWithCallback(this.$lodash.get(response, 'data', []), customer => customer.formatToFront())
        },
        preErrorHandler(e) {
            this.hasError = true
            this.errorHandler(e)
        },
    }
}
</script>